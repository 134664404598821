<template>
  <base-section
    id="about-us"
    space="100"
  >
    <v-container>
      <base-section-heading title="QUEM SOMOS" />
      <v-row>
        <v-col
          sm="12"
          md="6"
        >
          <p class="text-h6">
            Oferecemos serviços integrados em incorporação imobiliária, administração de Condomínios, agregando a gestão administrativa com a de pessoas, contratos, jurídica, financeira e contábil.
          </p>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <video
            controls
            width="100%"
          ><source src="https://video.wixstatic.com/video/9b494f_2192baf61f2a4cfd8b41b621bf862c41/1080p/mp4/file.mp4"></video>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutUs',
  }
</script>
